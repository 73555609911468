
import { Component, Vue } from "vue-property-decorator";
import { CartModule } from "@/store/cart";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { AuthModule } from "@/store/auth";

import CartTable from "@/modules/cart/components/CartTable.vue";
import LoginRegister from "@/modules/register/components/LoginRegister.vue";
import CheckoutForm from "@/modules/checkout/components/Form.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import BSection from "@/components/bs/BSection.vue";
import BContainer from "@/components/bs/BContainer.vue";
import BRow from "@/components/bs/BRow.vue";
import BCol from "@/components/bs/BCol.vue";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";

@Component({
  components: {
    BCol,
    BRow,
    BContainer,
    BSection,
    PageTitle,
    CartTable,
    LoginRegister,
    CheckoutForm,
  },
})
export default class OrdersIndex extends Vue {
  bLogin: boolean | null = null;

  get logged(): boolean {
    return AuthModule.isLogged;
  }

  get user(): Profile {
    return AuthModule.user;
  }

  get cartItems(): CartComponentItemsData | null {
    return CartModule.items;
  }
}
